@import '../../static/styles/variables';
@import '../../static/styles/fonts';
@import '../../static/styles/mediaqueries';

.modal {
  padding: 20px 64px 20px 64px;
  position: relative;
  border-radius: 5px;
  min-width: 728px;

  @include media(m-device) {
    min-width: 85%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3 {
    text-transform: uppercase;
    font-family: $baderCrompress, sans-serif;
    font-weight: 900;
    font-size: 28px;
    text-align: center;
    margin: 32px 0px;
    color: $black;
  }

  &__close_button {
    position: absolute;
    border-radius: 100%;

    outline: none;
    background: none;
    right: 10px;
    top: 10px;
    font-size: 28px;
    line-height: 13px;

    img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }

    &:hover, &:focus {
      outline: none;
    }
  }

  &__transition_enter {
    opacity: 1;
    transform: translateY(0);
  }

  &__transition_enter {
    opacity: 0.01;

    .modal {
      transform: translateY(-100px);
    }
  }

  &__transition_enter_active {
    opacity: 1;
    transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1), transform 500ms cubic-bezier(0.23, 1, 0.32, 1);

    .modal {
      transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
      transform: translateY(0);
    }
  }

  &__transition_exit {
    opacity: 1;
    transform: translateY(0);
  }

  &__transition_exit_active {
    opacity: 0.01;
    transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1);

    .modal {
      transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
      transform: translateY(-100px);
    }
  }
}

