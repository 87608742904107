@import '../../../static/styles/variables';
@import '../../../static/styles/mediaqueries';

.heading {
  font-size: 72px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: $baderCrompress;

  @include media(m-device){
    font-size: 42px !important;
    line-height: 42px;
    text-align: center;
  }
}