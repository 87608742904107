@font-face {
  font-family: 'Bader-bold-italic';
  font-style: 'italic';
  font-weight: 'bold';
  src: url('/static/fonts/GL_Bader_Bold_Italic.otf');
  src: url('/static/fonts/GL_Bader_Bold_Italic.woff');
}

@font-face {
  font-family: 'Bader-bold';
  font-style: 'normal';
  font-weight: 'bold';
  src: url('/static/fonts/GL_Bader_Bold.otf');
  src: url('/static/fonts/GL_Bader_Bold.woff');
}

@font-face {
  font-family: 'Bader-compress-regular';
  font-style: 'normal';
  font-weight: 'normal';
  src: url('/static/fonts/GL_Bader_Compress_Regular.otf');
  src: url('/static/fonts/GL_Bader_Compress_Regular.woff');
}

@font-face {
  font-family: 'Bader-compress';
  font-style: 'normal';
  font-weight: '400';
  src: url('/static/fonts/GL_Bader_Compress.otf');
  src: url('/static/fonts/GL_Bader_Compress.woff');
}

@font-face {
  font-family: 'Bader-regular';
  font-style: 'normal';
  font-weight: '400';
  src: url('/static/fonts/GL_Bader_Regular.otf');
  src: url('/static/fonts/GL_Bader_Regular.woff');
}

@font-face {
  font-family: 'Bader-narrow-bold';
  src: url('/static/fonts/GL_Bader_Narrow_Bold.otf');
  src: url('/static/fonts/GL_Bader_Narrow_Bold.woff');
}

@font-face {
  font-family: 'Bader-narrow';
  src: url('/static/fonts/GL_Bader_Narrow_Regular.otf');
  src: url('/static/fonts/GL_Bader_Narrow_Regular.woff');
}

@font-face {
  font-family: 'SensaBrush-Fill';
  src: url('/static/fonts/SensaBrush-Fill.otf');
  src: url('/static/fonts/SensaBrush-Fill.woff');
}

