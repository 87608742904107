@import "../../static/styles/variables";
@import "../../static/styles/mediaqueries";

.error {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top 100% right 50%;
  flex-direction: column;
  justify-content: space-between;


  &__content {
    max-width: 410px;
    @include media(l-device) {
      //margin-right: 20vh;
    }
  }

  &__title {
    color: $black;
    text-align: center;
    margin-top: 100px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 20px;
    @include media(m-device) {
      font-size: 42px;
    }
  }

  p {
    font-size: 53px;
    font-family: $baderCrompress;
    line-height: 53px;
    color: $black;
    text-align: center;
    padding: 20px 0;
    text-transform: uppercase;
    margin: 53px 0;

    @include media(m-device) {
      padding: 10px 0;
      font-size: 20px;
      line-height: 20px;
      margin: 20px 0;
    }
  }


  &__action_bloc {
    background-color: $white;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px 65px;
    @include media(m-device) {
      padding: 10px 30px;

    }

    a {
      margin: auto;
      background-color: $white;
      color: $black;
      font-family: $baderNarrow;
      font-size: 20px;
      text-decoration: underline;
      @include media(m-device) {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}
